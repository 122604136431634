import * as React from 'react';

import InfinitySymbol from 'assets/svg/white-infinity.svg';
import { Row } from 'components/row/Row';
import s from './Infinity.scss';

export const Infinity = () => {
    return (
        <div className={s.block}>
            <Row>
                <InfinitySymbol style={{ margin: '0 auto' }}/>
            </Row>
        </div>
    );
};
