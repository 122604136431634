import * as React from 'react';

import { Container } from 'components/container/Container';
import { Row } from 'components/row/Row';

import s from './OfferingBlock.scss';

interface IOfferingBlock {
    heading: React.ReactNode;
    description: React.ReactNode;
}

export const OfferingBlock = ({ heading, description }: IOfferingBlock) => (

                <div className={s.block__col}>
                    <h3 className={s.block__heading}>{heading}</h3>
                    <p className={s.block__description}>{description}</p>
                </div>

);
