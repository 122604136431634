import * as React from 'react';
import Helmet from 'react-helmet';
import { BlockText } from 'components/block-text/BlockText';
import { Link as HeaderLink } from 'components/header/Link';
import { Construction } from 'components/construction/Construction';
import { Infinity } from 'components/visualizations/Infinity';
import { OfferingBlock } from 'components/offering-blocks/OfferingBlock';
import { Container } from 'components/container/Container';
import { Row } from 'components/row/Row';
import { Segment } from 'components/segment/Segment';

export default () => (
  <>
    <Helmet title="Home" />
    <Construction
      message={(
        <>
          We really appreciate you for taking the time to visit us. You've caught us mid-reconstruction. We're working hard to complete it soon while we take care of client work. <br></br> Please feel free to <HeaderLink to="mailto:newbusiness@averlast.co" name="get in touch" />
        </>
      )}
    />

    <BlockText
      heading={(
        <>
        Averlast is a creative studio born and raised in New York City.
        </>
      )}
      // description={<Link to="/about" className={ link.__underline }> Explore how we work </Link>}
      description={false}
    />

    <Infinity />

    <BlockText
      heading={(
        <>

        We partner with small businesses & entrepreneurs to capture their unique stories and transform them into brands, products & experiences that stand the test of time.

        <br></br>
          <br></br>
          <br></br>
        </>
      )}
      // description={<Link to="/about" className={ link.__underline }> Explore how we work </Link>}
      description={false}
    />

    <Container>
        <Row>

    <OfferingBlock
      heading={(
        <>
          Strategy
        </>
      )}
      description={(
        <ul>
          <li>Discovery</li>
          <li>Brand audit and Architecture</li>
          <li>Brand Positioning</li>
          <li>Research</li>
          <li>Audience Analysis</li>
          <li>Digital Presence</li>
          <li>Experience Strategy</li>
          <li>Future Vision</li>
          <li>Market Oppurtunity </li>
        </ul>
      )}
    />

        <OfferingBlock
          heading={(
            <>
              Identity
        </>
          )}
          description={(
            <ul>
              <li>Visual Identity</li>
              <li>Logo design</li>
              <li>Messaging</li>
              <li>Voice & Tone</li>
              <li>Naming</li>
              <li>Packaging</li>
              <li>Print Design</li>
            </ul>
          )}
        />

        <OfferingBlock
          heading={(
            <>
              Experience
        </>
          )}
          description={(
            <ul>
              <li> Web Design</li>
              <li>User Experience Design</li>
              <li>Interface Design</li>
              <li>Front-end Development</li>
              <li>Mobile Development</li>
              <li>CMS Development</li>
              <li>E-commerce</li>
            </ul>
          )}
        />

        <OfferingBlock
          heading={(
            <>
              Engagement
        </>
          )}
          description={(
            <ul>
              <li>Digital Analytics</li>
              <li>Content Strategy</li>
              <li>Go-to-market strategy</li>
              <li>Customer Engagement Strategy</li>

            </ul>
          )}
        />

    </Row>
    </Container>

  </>
);
