import * as React from 'react';

import { Row } from 'components/row/Row';

import s from './Construction.scss';

interface IConstructionProps {
    message: React.ReactNode;
}

export const Construction = ({ message }: IConstructionProps) => {
    return (
            <div className={s.construction}>
                <div className={s.construction__container}>
                    <Row>
                        <div className={s.construction__col}>
                            <h4>{message}</h4>
                        </div>
                    </Row>
                </div>
            </div>
    );
};
